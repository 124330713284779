import { lazy, useEffect } from "react";
import { Route, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { INTEGRATIONS_OAUTH_URL } from "../../conf";
import { SentryRoutes as Routes } from "../../services/error-reporter";
import { useNavigate } from "../../services/router-utils";

const AppFrameProvider = lazy(async () => import("../../providers/AppFrame"));
const AppFrame = lazy(async () => import("../../data-components/AppFrame"));
const SignInOverlay = lazy(
  async () => import("../../dom-providers/SignInOverlay"),
);
const ReportsPage = lazy(async () => import("../insights/reports/index"));
const ReportsMessagesOverTimePage = lazy(
  async () => import("../insights/reports/messages-over-time"),
);
const ReportsSalesOverTimePage = lazy(
  async () => import("../insights/reports/sales-over-time"),
);
const InboxListPage = lazy(async () => import("../messaging/inbox/list"));
const InboxDetailPage = lazy(async () => import("../messaging/inbox/detail"));
const DraftsListPage = lazy(
  async () => import("../messaging/drafts/drafts/list"),
);
const DraftsDetailPage = lazy(
  async () => import("../messaging/drafts/drafts/detail"),
);
const OutboxListPage = lazy(
  async () => import("../messaging/drafts/outbox/list"),
);
const OutboxDetailPage = lazy(
  async () => import("../messaging/drafts/outbox/detail"),
);
const ScheduledListPage = lazy(
  async () => import("../messaging/drafts/scheduled/list"),
);
const ScheduledDetailPage = lazy(
  async () => import("../messaging/drafts/scheduled/detail"),
);
const TemplatesListPage = lazy(
  async () => import("../messaging/templates/list"),
);
const TemplatesDetailPage = lazy(
  async () => import("../messaging/templates/detail"),
);
const EmailSignatureDetailPage = lazy(
  async () => import("../messaging/signature/detail"),
);
const CrmCustomersPage = lazy(async () => import("../crm/customers/index"));
const CrmCustomersSegmentPage = lazy(
  async () => import("../crm/customers/segment"),
);
const StoriesPage = lazy(async () => import("../creative/stories/list"));
const StoriesDetailPage = lazy(
  async () => import("../creative/stories/detail"),
);
const AssetsPage = lazy(async () => import("../creative/assets/list"));
const AssetsDetailPage = lazy(async () => import("../creative/assets/detail"));
const AssetsUploadPage = lazy(async () => import("../creative/assets/upload"));
const BulkEditAssetsPage = lazy(async () => import("../creative/assets/bulk"));
const ProductsPage = lazy(async () => import("../creative/products/list"));
const SettingsTeamSignaturePage = lazy(
  async () => import("../settings/teams/signature/detail"),
);
const SettingsIntegrationsDetailPage = lazy(
  async () => import("../settings/integrations/detail"),
);
const SettingsCustomersPage = lazy(async () => import("../settings/customers"));
const SettingsCustomerFieldDetailPage = lazy(
  async () => import("../settings/customer-field-detail"),
);
const SettingsCustomerFieldCreatePage = lazy(
  async () => import("../settings/customer-field-create"),
);
const SettingsDetailSetupMessagingPage = lazy(
  async () => import("../settings/messaging/Setup"),
);
const CampaignsListPage = lazy(
  async () => import("../messaging/campaigns/list"),
);
const CampaignsDetailPage = lazy(
  async () => import("../messaging/campaigns/detail"),
);

export interface RoutesConfigProps {}

function RoutesConfig(_props: RoutesConfigProps) {
  const routePrefix = window.top === window.self ? "/admin" : "/_embed/admin";

  return (
    <Routes>
      <Route element={<Redirect to={`${routePrefix}/crm/home`} />} path="/" />
      <Route element={<SignInOverlay />}>
        <Route element={<AppFrameProvider />}>
          <Route element={<AppFrame />}>
            <Route
              element={<Redirect to={`${routePrefix}/crm/campaigns`} />}
              path="/messaging/campaigns"
            />
            <Route
              element={<CampaignsListPage />}
              path={`${routePrefix}/crm/campaigns`}
            />
            <Route
              element={<PlaceholderRoute />}
              path={`${routePrefix}/crm/campaigns/create`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/campaigns/:campaignId`} />
              }
              path="/messaging/campaigns/:campaignId"
            />
            <Route
              element={<CampaignsDetailPage />}
              path={`${routePrefix}/crm/campaigns/:campaignId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/inbox/:type`} />}
              path="/messaging/inbox/:type"
            />
            <Route
              element={<InboxListPage />}
              path={`${routePrefix}/crm/inbox/:type`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/inbox/:type/:channelId`} />
              }
              path="/messaging/inbox/:type/:channelId"
            />
            <Route
              element={<InboxDetailPage />}
              path={`${routePrefix}/crm/inbox/:type/:channelId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/inbox/me`} />}
              path="/messaging/inbox"
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/inbox/me`} />}
              path={`${routePrefix}/crm/inbox`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/drafts`} />}
              path="/messaging/drafts"
            />
            <Route
              element={<DraftsListPage />}
              path={`${routePrefix}/crm/drafts`}
            />
            <Route
              element={<PlaceholderRoute />}
              path={`${routePrefix}/crm/drafts/create`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/drafts/:draftId`} />}
              path="/messaging/drafts/:draftId"
            />
            <Route
              element={<DraftsDetailPage />}
              path={`${routePrefix}/crm/drafts/:draftId`}
            />
            <Route
              element={<DraftsDetailPage />}
              path={`${routePrefix}/crm/customers/segments/:segmentId/drafts/:draftId/`}
            />
            <Route
              element={<DraftsDetailPage />}
              path={`${routePrefix}/crm/customers/:customerId/drafts/:draftId/`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/outbox`} />}
              path="/messaging/outbox"
            />
            <Route
              element={<OutboxListPage />}
              path={`${routePrefix}/crm/outbox`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/outbox/:draftId`} />}
              path="/messaging/outbox/:draftId"
            />
            <Route
              element={<OutboxDetailPage />}
              path={`${routePrefix}/crm/outbox/:draftId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/scheduled`} />}
              path="/messaging/scheduled"
            />
            <Route
              element={<ScheduledListPage />}
              path={`${routePrefix}/crm/scheduled`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/scheduled/:draftId`} />
              }
              path="/messaging/scheduled/:draftId"
            />
            <Route
              element={<ScheduledDetailPage />}
              path={`${routePrefix}/crm/scheduled/:draftId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/templates`} />}
              path="/messaging/templates"
            />
            <Route
              element={<TemplatesListPage />}
              path={`${routePrefix}/crm/templates`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/templates/:templateId`} />
              }
              path="/messaging/templates/:templateId"
            />
            <Route
              element={<TemplatesDetailPage />}
              path={`${routePrefix}/crm/templates/:templateId`}
            />
            <Route
              element={<EmailSignatureDetailPage />}
              path={`${routePrefix}/crm/signature`}
            />
            <Route
              element={<CrmCustomersPage />}
              path={`${routePrefix}/crm/customers`}
            />
            <Route
              element={<CrmCustomersSegmentPage />}
              path={`${routePrefix}/crm/customers/segments/:segmentId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/reports`} />}
              path="/reports"
            />
            <Route
              element={<ReportsPage />}
              path={`${routePrefix}/crm/reports`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/reports/sales-over-time`} />
              }
              path="/reports/sales-over-time"
            />
            <Route
              element={<ReportsSalesOverTimePage />}
              path={`${routePrefix}/crm/reports/sales-over-time`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/reports/sales-over-time/segments/:segmentId`}
                />
              }
              path="/reports/sales-over-time/segments/:segmentId"
            />
            <Route
              element={<ReportsSalesOverTimePage />}
              path={`${routePrefix}/crm/reports/sales-over-time/segments/:segmentId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/reports/:reportId`} />}
              path="/reports/:reportId"
            />
            <Route
              element={<ReportsMessagesOverTimePage />}
              path={`${routePrefix}/crm/reports/:reportId`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/reports/:reportId/segments/:segmentId`}
                />
              }
              path="/reports/:reportId/segments/:segmentId"
            />
            <Route
              element={<ReportsMessagesOverTimePage />}
              path={`${routePrefix}/crm/reports/:reportId/segments/:segmentId`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/stories`} />}
              path="/stories"
            />
            <Route
              element={<StoriesPage />}
              path={`${routePrefix}/crm/stories`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/stories/:storyId`} />}
              path="/stories/:storyId"
            />
            <Route
              element={<StoriesDetailPage />}
              path={`${routePrefix}/crm/stories/:storyId`}
            />
            <Route
              element={<ProductsPage />}
              path={`${routePrefix}/crm/products`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/assets`} />}
              path="/assets"
            />
            <Route
              element={<AssetsPage />}
              path={`${routePrefix}/crm/assets`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/assets/upload`} />}
              path="/assets/upload"
            />
            <Route
              element={<AssetsUploadPage />}
              path={`${routePrefix}/crm/assets/upload`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/assets/bulk`} />}
              path="/assets/bulk"
            />
            <Route
              element={<BulkEditAssetsPage />}
              path={`${routePrefix}/crm/assets/bulk`}
            />
            <Route
              element={<Redirect to={`${routePrefix}/crm/assets/:assetId`} />}
              path="/assets/:assetId"
            />
            <Route
              element={<AssetsDetailPage />}
              path={`${routePrefix}/crm/assets/:assetId`}
            />
            <Route
              element={
                <Redirect to={`${routePrefix}/crm/settings/customers`} />
              }
              path="/settings/customers"
            />
            <Route
              element={<SettingsCustomersPage />}
              path={`${routePrefix}/crm/settings/customers`}
            />
            <Route
              element={<SettingsCustomerFieldDetailPage />}
              path={`${routePrefix}/crm/settings/customers/fields/:customerFieldId`}
            />
            <Route
              element={<SettingsCustomerFieldCreatePage />}
              path={`${routePrefix}/crm/settings/customers/fields/create`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/settings/teams/:teamId/signature`}
                />
              }
              path="/settings/teams/:teamId/signature"
            />
            <Route
              element={<SettingsTeamSignaturePage />}
              path={`${routePrefix}/crm/settings/teams/:teamId/signature`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/settings/integrations/:integrationId`}
                />
              }
              path="/settings/integrations/:integrationId"
            />
            <Route
              element={<SettingsIntegrationsDetailPage />}
              path={`${routePrefix}/crm/settings/integrations/:integrationId`}
            />
            <Route
              element={<SettingsIntegrationsDetailPage />}
              path={`${routePrefix}/crm/settings/integrations/:integrationId/api-keys`}
            />
            <Route
              element={<ExternalRedirect url={INTEGRATIONS_OAUTH_URL} />}
              path="/settings/integrations"
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/settings/messaging/domains/setup`}
                />
              }
              path="/settings/messaging/domains/setup"
            />
            <Route
              element={<SettingsDetailSetupMessagingPage />}
              path={`${routePrefix}/crm/settings/messaging/domains/setup`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/settings/messaging/emails/setup`}
                />
              }
              path="/settings/messaging/emails/setup"
            />
            <Route
              element={<SettingsDetailSetupMessagingPage />}
              path={`${routePrefix}/crm/settings/messaging/emails/setup`}
            />
            <Route
              element={
                <Redirect
                  to={`${routePrefix}/crm/settings/messaging/phones/setup`}
                />
              }
              path="/settings/messaging/phones/setup"
            />
            <Route
              element={<SettingsDetailSetupMessagingPage />}
              path={`${routePrefix}/crm/settings/messaging/phones/setup`}
            />
          </Route>
        </Route>
      </Route>
      <Route element={<PlaceholderRoute />} path={`${routePrefix}/*`} />
    </Routes>
  );
}

export default RoutesConfig;

function PlaceholderRoute() {
  return <div />;
}

function Redirect({ to }: { to: string }) {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let path = to;
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        path = path.replace(`:${key}`, value);
      }
    }

    const querystring = searchParams.toString();

    if (querystring) {
      navigate(`${path}?${querystring}`);
    } else {
      navigate(path);
    }
  });

  return null;
}

function ExternalRedirect({ url }: { url: string }) {
  useEffect(() => {
    window.open(url, "_blank");
  }, [url]);

  return null;
}
