import { createContext } from "react";

import { withConsumer } from "../../services/with-consumer";

export type CacheId =
  | "clientsCacheBuster"
  | "lookbooksCacheBuster"
  | "lookbookItemsCacheBuster";

export interface ConsumerProps {
  cache: { [K in CacheId]: number };
  onBustCache: (cacheId: CacheId) => void;
}

export const getInitialContext = (): ConsumerProps => ({
  cache: {
    clientsCacheBuster: Date.now(),
    lookbooksCacheBuster: Date.now(),
    lookbookItemsCacheBuster: Date.now(),
  },
  onBustCache: () => undefined,
});

export const CacheBusterContext =
  createContext<ConsumerProps>(getInitialContext());

export const { Provider: CacheBusterProvider, Consumer: CacheBusterConsumer } =
  CacheBusterContext;

export const withCacheBuster = withConsumer(CacheBusterConsumer);
