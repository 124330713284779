import { createContext } from "react";

import type { Firestore } from "../../services/firebase";

export interface ConsumerProps {
  firestore: Firestore | null;
}

export const getInitialContext = (): ConsumerProps => ({
  firestore: null,
});

export const FirebaseContext =
  createContext<ConsumerProps>(getInitialContext());
