import type { ApiLookbooksItemsList } from "@endearhq/service-schema/lib/Models.schema";
import { createContext } from "react";

import { withConsumer } from "../../services/with-consumer";

type Item = ElementOf<ApiLookbooksItemsList["response"]["200"]["items"]>;

export interface ConsumerProps {
  lookbookItemsCache: Partial<{
    [sectionId: string]: Partial<{
      [itemId: string]: Item;
    }>;
  }>;
  onUpsertLookbookItemsCache: (
    sectionId: string,
    items: Item[] | undefined,
  ) => void;
  onDiscardLookbookItemsCache: (sectionId: string, itemId: string) => void;
}

export const getInitialContext = (): ConsumerProps => ({
  lookbookItemsCache: {},
  onUpsertLookbookItemsCache: () => undefined,
  onDiscardLookbookItemsCache: () => undefined,
});

export const LookbookItemsCacheContext =
  createContext<ConsumerProps>(getInitialContext());

export const {
  Provider: LookbookItemsCacheProvider,
  Consumer: LookbookItemsCacheConsumer,
} = LookbookItemsCacheContext;

export const withLookbookItemsCache = withConsumer(LookbookItemsCacheConsumer);
