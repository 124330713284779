import "./App.css";

import { usePrevious } from "@endearhq/app-shared";
import {
  Environment,
  UserRole,
  useSendAnalyticsMutation,
} from "@endearhq/graphql-types";
import type { AppProviderProps } from "@endearhq/polaris";
import { AppProvider } from "@endearhq/polaris";
import { assertNever } from "@endearhq/toolchest-utilities";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import { Suspense, useContext, useEffect, useMemo } from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { DOPPLER_CONFIG, RELEASE } from "../../conf";
import LinkComponent from "../../data-components/LinkComponent";
import { RolesContext } from "../../providers/Roles";
import { Sentry } from "../../services/error-reporter";
import { baseTheme } from "../../theme";
import Routes from "./Routes";

const theme: AppProviderProps["theme"] = {
  ...baseTheme,
  colorScheme: "light",
  colors: {
    primary: "#00A2D6",
  },
};

let env: Environment;
switch (DOPPLER_CONFIG) {
  case "dev":
  case "dev_preview":
  case "dev_tunnel": {
    env = Environment.Development;
    break;
  }
  case "stg":
  case "prd_loc": {
    env = Environment.Staging;
    break;
  }
  case "prd": {
    env = Environment.Production;
    break;
  }
  default: {
    assertNever(DOPPLER_CONFIG);
  }
}

const localesLoadPath =
  window.top === window.self
    ? "/locales/{{lng}}/{{ns}}.json"
    : "/_embed/locales/{{lng}}/{{ns}}.json";

i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    ns: [
      "app",
      "polaris",
      "common",
      "messaging",
      "settings",
      "insights",
      "crm",
      "creative",
      "polarisExtended",
    ],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
    supportedLngs: ["en", "es", "fr"],
    backend: {
      loadPath: localesLoadPath,
      queryStringParams: { v: RELEASE },
    },
  });

function usePolarisTranslations() {
  const { t, ready } = useTranslation("polaris");
  return useMemo(
    () => ({
      ready,
      translations: { Polaris: t("Polaris", { returnObjects: true }) },
    }),
    [t, ready],
  );
}

function App() {
  const location = useLocation();

  const { currentUser, groupId, onGroupIdChange } = useContext(RolesContext);

  const [sendAnalytics] = useSendAnalyticsMutation();

  const navigate = useNavigate();

  const { i18n } = useTranslation("common");

  const { ready, translations } = usePolarisTranslations();

  const locale = i18n.language;

  useEffect(() => {
    async function onEvent(e: MessageEvent) {
      if (e.data.type === "languageChange") {
        if (e.data.language !== locale) {
          i18next.changeLanguage(e.data.language);
        }
      } else if (e.data.type === "navigate") {
        navigate(e.data.to, { replace: true });
      } else if (e.data.type === "team") {
        if (e.data.id !== groupId) {
          onGroupIdChange();
        }
      } else if (e.data.type === "scrollToTop") {
        document
          .querySelector('[data-polaris-scrollable="true"]')
          ?.scrollTo({ top: 0 });
      }
    }

    window.addEventListener("message", onEvent);

    return () => {
      window.removeEventListener("message", onEvent);
    };
  }, [navigate, locale, groupId, onGroupIdChange]);

  const prevCurrentUser = usePrevious(currentUser);
  useEffect(() => {
    if (currentUser && !prevCurrentUser) {
      if (!currentUser.data.roles.includes(UserRole.ArthurAdmin)) {
        sendAnalytics({ variables: { appVersion: RELEASE, env } });

        Sentry.setUser({
          id: currentUser.data.id,
          email: currentUser.data.account.email ?? undefined,
          username: currentUser.data.account.username ?? undefined,
        });
      }
    }
  }, [currentUser, prevCurrentUser, location, sendAnalytics]);

  return (
    <main className="Endear--AppRoot">
      <div>
        {ready ? (
          <AppProvider
            i18n={translations}
            linkComponent={LinkComponent}
            theme={theme}>
            <Suspense fallback={null}>
              <Routes />
            </Suspense>
          </AppProvider>
        ) : null}
      </div>
    </main>
  );
}

export default App;
