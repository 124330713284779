import { createContext } from "react";

import { withConsumer } from "../../services/with-consumer";

export interface ErrorToDisplay {
  id: string;
  content: string;
}

export interface ConsumerProps {
  errors: ErrorToDisplay[];
  onDismiss: (id: string) => void;
}

export const getInitialState = () => ({
  errors: [],
  onDismiss: () => undefined,
});

export const ErrorsToDisplayContext =
  createContext<ConsumerProps>(getInitialState());

export const {
  Provider: ErrorsToDisplayProvider,
  Consumer: ErrorsToDisplayConsumer,
} = ErrorsToDisplayContext;

export const withErrorsToDisplay = withConsumer(ErrorsToDisplayConsumer);
